import { Route, Routes } from 'react-router-dom';
import { oldSiteRoutesConfig } from './oldSiteRoutesConfig';
import { routesConfig } from './routesConfig';

const Router = () => {
  return (
    <Routes>
      {[...routesConfig, ...oldSiteRoutesConfig].map(
        ({ path, element: Element }) => {
          if (!Array.isArray(path))
            return <Route key={path} path={path} element={Element} />;
          return path.map((singlePath) => (
            <Route key={singlePath} path={singlePath} element={Element} />
          ));
        }
      )}
    </Routes>
  );
};

export default Router;
